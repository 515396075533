<template>
  <div v-if="comp" class="frm">
    <div class="page-header">基本信息</div>
    <div>
      <CertState :comp="comp"></CertState>
    </div>
    <!-- v-if="comp.cert_stat == 2" -->
    <div class="block">
      <div class="header flex">
        <div>企业基本信息</div>
        <div v-if="!compInfoFlag" class="edit" @click="edit_compInfo">编辑</div>
      </div>
      <div class="info-list">
        <div>
          <label>机构号</label>
          <span v-if="comp.comp_code">{{ comp.comp_code }}</span>
          <span v-if="!comp.comp_code">--</span>
        </div>
        <div>
          <label>企业名称</label>
          <span v-if="comp.name">{{ comp.name }}</span>
          <span v-if="!comp.name">--</span>
        </div>
        <div>
          <label>企业简称</label>
          <el-input
            v-if="compInfoFlag"
            style="max-width:350px;"
            v-model="comp.short_name"
          ></el-input>
          <template v-if="!compInfoFlag && comp.short_name">{{ comp.short_name }}</template>
          <span v-if="!compInfoFlag && !comp.short_name">--</span>
        </div>
        <div style="position:relative;" class="flex">
          <label>所在地区</label>
          <RegionSelect
            width="240px"
            v-if="compInfoFlag"
            v-model="comp"
          ></RegionSelect>
          <template v-if="!compInfoFlag && comp.province">{{ comp.province }}-{{ comp.city }}</template>
          <span v-if="!compInfoFlag && !comp.province && !comp.city">--</span>
        </div>
        <div>
          <label>地址</label>
          <el-input
            v-if="compInfoFlag"
            style="max-width:350px;"
            v-model="comp.addr"
          ></el-input>
          <template v-if="!compInfoFlag && comp.addr">{{ comp.addr }}</template>
          <span v-if="!compInfoFlag && !comp.addr">--</span>
        </div>
        <div class="flex">
          <label>LOGO</label>
          <!-- <el-upload
            v-if="compInfoFlag"
            class="comp-cert-uploader"
            action="xx"
            :show-file-list="false"
            :accept="uploadAccept"
            :http-request="uploadLogo"
            :before-upload="beforeUpload"
          >
            <img v-if="comp.logo"
              :src="imgUrl + comp.logo"
              class="comp-cert-logo"
            />
            <div
              class="comp-cert-logo-uploader-icon flex flex-v flex-pack-center"
            >
              <i class="el-icon-plus "></i>
              <span>重新上传</span>
            </div>
          </el-upload> -->
          <UploadImg v-if="compInfoFlag" v-model="comp.logo" prefix='comp/logo'></UploadImg>
          <div
            v-else
            style="width:120px;height:120px;border:1px dashed rgba(204,204,204,1);overflow: hidden;display: inline-block;"
          >
            <img v-if="comp.logo"
              :src="imgUrl + comp.logo"
              style="width: 100%;height: 100%;"
            />
            <img v-else style="width: 100%;height: 100%;"
             :src="staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px'"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="compInfoFlag" class="block" style="padding:20px 30px;">
      <el-button size="small" @click="cancelEditCompInfo()">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="save_compInfo"
        style="margin-left: 30px;"
        >保存</el-button
      >
    </div>
    <!-- v-if="comp.cert_stat == 2" -->
    <div class="block">
      <div class="header">营业执照</div>
      <div class="info-list">
        <div v-if="comp.cert_stat == 2">
          <div class="credit-img">
            <img
              v-if="comp.credit_image"
              :src="imgUrl + comp.credit_image"
              style="width: 100%;"
            />
          </div>
        </div>
        <div v-if="comp.cert_stat == 2">统一社会信用代码：{{ comp.credit_id_code }}</div>
        <div style="text-align:center;">
          <img style="width:160px;margin-bottom:15px;"
             src="@/assets/images/comp/approve_no.jpg"/>
          <div class="font14-grey1">认证成功后显示营业执照</div>
        </div>
      </div>
    </div>
    <!-- v-if="comp.cert_stat == 2" -->
    <div class="block">
      <div class="header flex">
        <div>联系人信息</div>
        <div v-if="!editing" class="edit" @click="edit">编辑</div>
      </div>
      <div class="info-list">
        <div class="flex flex-align-center">
          <label style="width:unset;">姓名</label>
          <el-input
            size="small"
            v-if="editing"
            style="max-width:350px;"
            v-model="contact.contact_name"
          ></el-input>
          <template v-if="!editing && comp.contact_name">{{ comp.contact_name }}</template>
          <span v-if="!editing && !comp.contact_name">--</span>
        </div>
        <div class="flex flex-align-center">
          <label style="width:unset;">职务</label>
          <el-input
            size="small"
            v-if="editing"
            style="max-width:350px;"
            v-model="contact.contact_position"
          ></el-input>
          <template v-if="!editing && comp.contact_position">{{ comp.contact_position }}</template>
          <span v-if="!editing && !comp.contact_position">--</span>
        </div>
        <div class="flex flex-align-center">
          <label style="width:unset;">手机</label>
          <el-input
            size="small"
            v-if="editing"
            style="max-width:350px;"
            v-model="contact.contact_mobile"
          ></el-input>
          <template v-if="!editing && comp.contact_mobile">{{ comp.contact_mobile }}</template>
          <span v-if="!editing && !comp.contact_mobile">--</span>
        </div>
      </div>
    </div>
    <div v-if="editing" class="block" style="padding:20px 30px;">
      <el-button size="small" @click="editing = false">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="save"
        style="margin-left: 30px;"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { IMG_URL_PRE,STATIC_URL_PRE } from "@/config";
import { getCompInfoById, setCompanyInfo } from "@/service/company";
import { uploadFile,delFile } from "@/service/common";
import CertState from "./CertState";
import RegionSelect from "@/components/region/Index.vue";
import UploadImg from '@/components/upload/UploadImg.vue';
export default {
  components: {
    CertState,
    RegionSelect,
    UploadImg
  },
 
    data() {
    return {
      creditBg: require("../../assets/images/yyzz.png"),
      uploadAccept: "image/jpeg,image/gif,image/png",
      imgUrl: IMG_URL_PRE,
      staticUrl:STATIC_URL_PRE,
      comp: null,
      prevComp: null,
      editing: false,
      compInfo: null,
      compInfoFlag: false,
      contact: null,
      saveImg:null,
    };
  },
  async created() {
    if (this.$store.state.loginUser.user.comp_creator==1) {
      this.comp = await getCompInfoById(this.$store.state.loginUser.comp.id);
    } else {
      this.comp = this.$store.state.loginUser.comp;
    }
    this.saveImg = this.comp.logo;
  },
  methods: {
    cancelEditCompInfo() {
      this.comp = { ...this.prevComp };
      this.compInfoFlag = false;
    },
    edit_compInfo() {
      this.prevComp = { ...this.comp };
      this.compInfoFlag = true;
    },
    save_compInfo() {
      (this.compInfo = {
        comp_id: this.comp.id,
        short_name: this.comp.short_name,
        province: this.comp.province,
        city: this.comp.city,
        addr: this.comp.addr,
        logo: this.comp.logo
      }),
        setCompanyInfo(this.compInfo)
          .then(rst => {
            Object.assign(this.comp, this.contact);
            this.compInfoFlag = false;
            this.$message.success("保存成功");
            // delFile(this.saveImg).then(() => {});
          })
          .catch(err => {
            this.$message.error(err.message);
          });
    },
    uploadLogo(item) {
      uploadFile(item.file,'comp/logo', {
            onUploadProgress: event => {
            item.file.percent = (event.loaded / event.total) * 100;
            item.onProgress(item.file);
            }
        }).then(url => {
          this.comp.logo = url;
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传LOGO图片大小不能超过 1MB!");
      }
      return isLt2M;
    },
    edit() {
      (this.contact = {
        comp_id: this.comp.id,
        contact_name: this.comp.contact_name,
        contact_position: this.comp.contact_position,
        contact_mobile: this.comp.contact_mobile
      }),
        (this.editing = true);
    },
    save() {
      setCompanyInfo(this.contact)
        .then(rst => {
          Object.assign(this.comp, this.contact);
          this.editing = false;
          this.$message.success("保存成功");
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }
  }
};
</script>
<style scoped src="./style.css"></style>
<style scoped lang="less">
.block {
  .header {
    padding: 20px;
  }
}
.el-input,
.el-cascader {
  width: 240px;
}
.frm {
  font-weight: 500;
}
.header {
  justify-content: space-between;
  div:first-child {
    color: #3b3b3b;
    font-weight: 600;
  }
  .edit {
    color: #5074EE;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
  }
}
.comp-cert-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 120px;
  height: 120px;
}

.comp-cert-uploader:hover {
  border-color: #409eff;
}

.comp-cert-logo-uploader-icon {
  color: #5074ee;
  font-size: 28px;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  span {
    line-height: 1;
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 500;
  }
}

.comp-cert-logo {
  width: 120px;
  height: 120px;
  display: block;
}

.frm {
  height: 100%;
  overflow: auto;
}
.info-list {
  font-size: 14px;
  font-weight: 500;
  color: #808080;
  padding: 20px;
  > div {
    padding: 10px 0;
    > label {
      font-weight: bold;
      &:first-child {
        width: 56px;
        text-align: right;
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;
        color: #4d4d4d;
      }
    }
  }
}

.credit-img {
  width: 120px;
  // height: 160px;
  border: 1px dashed rgba(204, 204, 204, 1);
  overflow: hidden;
  display: inline-block;
  background: url("../../assets/images/yyzz.png") no-repeat center center;
}
</style>
